import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { Formik } from "formik";
import ModalDelete from "./ModaleDelete";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import StatusSelect from "../selects/StatusSelect";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export default function AddProject({
  open,
  handleCancel,
  handleSave,
  handleDelete,
  project
}) {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {project ? project.name : "New Project"}
      </DialogTitle>
      <Formik
        initialValues={
          project || {
            name: "",
            estimatedDays: 0,
            completedPercentage: 0,
            isSpecific: false,
            startDate: null,
            endDate: null,
            status: "CREATION",
            notes: "",
            deletedAt: null,
            ratioHrsDays: 6
          }
        }
        // validationSchema={validationSchema}
        render={({ values, handleChange, isSubmitting }) => {
          const {
            name,
            notes,
            status,
            startDate,
            endDate,
            isSpecific,
            completedPercentage,
            estimatedDays,
            ratioHrsDays
          } = values;

          const handleDateChange = name => date => {
            handleChange({ target: { name, value: date } });
          };

          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                handleSave(values);
              }}
            >
              <DialogContent>
                <DialogContentText>
                  Please fill in the following informations
                </DialogContentText>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={name}
                  onChange={handleChange}
                  fullWidth
                  required
                  autoFocus
                  margin="normal"
                  helperText="Project name must be unique"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isSpecific"
                      name="isSpecific"
                      label="Customer specific"
                      onChange={handleChange}
                      checked={isSpecific}
                    />
                  }
                  label="Specific"
                />
                <TextField
                  id="ratioHrsDays"
                  name="ratioHrsDays"
                  label="Ratio hours/days"
                  type="number"
                  onChange={handleChange}
                  value={ratioHrsDays}
                  fullWidth
                  margin="normal"
                  inputProps={{ min: "0", step: "0.1", max: "24" }}
                  required={true}
                />
                <TextField
                  id="estimatedDays"
                  name="estimatedDays"
                  label="estimatedDays"
                  type="number"
                  onChange={handleChange}
                  value={estimatedDays}
                  fullWidth
                  margin="normal"
                  inputProps={{ min: "0", step: "0.1" }}
                  required={true}
                ></TextField>
                <TextField
                  id="completedPercentage"
                  name="completedPercentage"
                  label="Completed (%)"
                  type="number"
                  onChange={handleChange}
                  value={completedPercentage}
                  fullWidth
                  margin="normal"
                  inputProps={{ min: "0", step: "0.1", max: "100" }}
                  required={true}
                ></TextField>

                <StatusSelect
                  name="status"
                  label="Status"
                  onChange={handleChange}
                  value={status}
                  fullWidth
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="startDate"
                    name="startDate"
                    label="Estimated start date"
                    value={startDate}
                    onChange={handleDateChange("startDate")}
                    KeyboardButtonProps={{
                      "aria-label": "Start date"
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="endDate"
                    name="endDate"
                    label="Estimated end date"
                    value={endDate}
                    onChange={handleDateChange("endDate")}
                    KeyboardButtonProps={{
                      "aria-label": "End date"
                    }}
                    disabled={!startDate}
                    minDate={startDate}
                    minDateMessage="End date cannot be before start date"
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  id="notes"
                  name="notes"
                  label="Notes"
                  onChange={handleChange}
                  value={notes}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                />
              </DialogContent>
              <DialogActions>
                {project && (
                  <ModalDelete project={project} handleDelete={handleDelete} />
                )}
                <Button onClick={handleCancel} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      ></Formik>
    </Dialog>
  );
}
