export const getSanitizedLocalStorageValue = (localStorageKey) => {
  const localStorageValue = window.localStorage.getItem(localStorageKey);
  if (
    !localStorageValue ||
    localStorageValue?.length === 0 ||
    localStorageValue === 'null'
  )
    return "";
  else return localStorageValue;
};
