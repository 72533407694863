import React from 'react';
import TagBox from 'devextreme-react/tag-box';

export const ProjectSelect = ({
  projects,
  selectedProjects,
  setSelectedProjects,
}) => (
  <TagBox
    value={selectedProjects}
    dataSource={projects}
    valueExpr="id"
    displayExpr="name"
    searchEnabled
    onValueChanged={({ value }) => setSelectedProjects(value)}
    placeholder="Choisissez les projets qui vous interessent (todo : localStorage)"
  />
);
