import React, { useState } from "react";
import { Button } from "@material-ui/core";
import {
  useProjects,
  useUsers,
  useThemes,
  useTimeInputsPerThemes
} from "../Project/ProjectsApi";
import Loading from "../lib/Loading";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { keyBy } from "lodash";

import FinancialExtractTable from "./FinancialExtractTable";
import { generateExportCSV } from "./csvExtraction";

const monthes = [];
for (let i = 2; i >= 0; i--) {
  const date = moment().add(-i, "month");
  monthes.push({ name: date.format("MMM") });
}

export default function FinancialExtract() {
  const [
    { loading: loadingTimeInputsPerThemes, data: timeInputsPerTheme }
  ] = useTimeInputsPerThemes({
    from: moment()
      .subtract(2, "months")
      .startOf("month"),
    to: moment().endOf("month")
  });

  const [{ loading, data: projects }] = useProjects(
    ["userStories", "userStories.timeInputs"],
    []
  );
  const [{ loadingThemes, data: themes }] = useThemes();
  const [key, setKey] = useState("name");
  const [order, setOrder] = useState("asc");
  const [{ loading: loadingUsers, data: users }] = useUsers();
  const componentRef = React.useRef();

  if (loading || loadingUsers || loadingThemes || loadingTimeInputsPerThemes)
    return <Loading />;

  const userStories = projects.flatMap(x => x.userStories);
  const userLookup = keyBy(users, "id");

  const toggleOrder = key => {
    setKey(key);
    order === "asc" ? setOrder("desc") : setOrder("asc");
  };
  const financialReducer = array => {
    return array.reduce(
      (total, ti) => Math.floor(((total + Number(ti.minutes) / 60) * 10) / 10),
      0
    );
  };
  // const financialReducer = array => {
  //   return array.reduce(
  //     (total, ti) =>
  //       Math.round(((total + Number(ti.minutes) / 60) * 100) / 100),
  //     0
  //   );
  // };
  const { headers, csvData } = generateExportCSV(
    monthes,
    projects,
    themes,
    timeInputsPerTheme,
    financialReducer,
    userLookup
  );

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button variant="contained" color="primary">
            Print
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle={{ margin: "2px" }}
      />
      <CSVLink
        data={csvData}
        headers={headers}
        filename={"projects.csv"}
        className=""
        target="_blank"
      >
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "1rem" }}
        >
          Export to CSV
        </Button>
      </CSVLink>
      <FinancialExtractTable
        componentRef={componentRef}
        monthes={monthes}
        toggleOrder={toggleOrder}
        projects={projects}
        key={key}
        financialReducer={financialReducer}
        userLookup={userLookup}
        userStories={userStories}
        order={order}
      />
      <FinancialExtractTable
        componentRef={componentRef}
        monthes={monthes}
        toggleOrder={toggleOrder}
        key={key}
        financialReducer={financialReducer}
        userLookup={userLookup}
        userStories={userStories}
        order={order}
        themes={themes}
        timeInputsPerTheme={timeInputsPerTheme}
      />
    </>
  );
}
