import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  ListItemText,
  Checkbox,
  Select,
  FormControl
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

export default function StatusSelect({
  selectedStatus,
  onSelectedStatusChanged
}) {
  const classes = useStyles();

  const statusKeys = Object.keys(selectedStatus);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-multiple-checkbox"> Status </InputLabel>
      <Select
        multiple
        renderValue={selected => selected.join(", ")}
        value={statusKeys
          .filter(state => selectedStatus[state])
          .map(status => status)}
        onChange={e => {
          onSelectedStatusChanged({ type: "TOGGLE", payload: e.target.value });
        }}
      >
        {statusKeys.map(status => (
          <MenuItem key={status} value={status} name={status}>
            <Checkbox checked={selectedStatus[status]} />
            <ListItemText primary={status} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
