import { groupBy } from "lodash";
import * as moment from "moment";
/**
 * headers est un tableau d'objet contenant 1 labels et 1 key
 * csvData est un tableau d'objet contenant les datas de chaque projet et de chaque theme
 * @param {*} monthes Liste des mois
 * @param {*} projects Liste des projets
 * @param {*} themes Liste des themes
 * @param {*} timeInputsPerTheme Liste des temps pour les themes
 * @param {*} financialReducer Methode qui calcule le total par mois en heure d'une liste de themes ou de projets
 * @param {*} userLookup Liste de tous les utilisateurs
 */
export function generateExportCSV(
  monthes,
  projects,
  themes,
  timeInputsPerTheme,
  financialReducer,
  userLookup
) {
  const totalProjects = {};
  const totalThemes = {};

  // Pour chaque mois on initialise les valeurs pour les propriétes "internal" et "external" de tous les projets et themes à 0
  monthes.forEach(month => {
    totalProjects[month.name] = { internal: 0, external: 0 };
    totalThemes[month.name] = { internal: 0, external: 0 };
  });
  // On crée une fonction qui génère le tableau csv
  const generateDataForCSV = (datas, total) => {
    return datas.map(
      ({
        id,
        name,
        isSpecific,
        estimatedDays,
        startDate,
        endDate,
        userStories,
        isValued
      }) => {
        // Pour chaque projets, on recupere les timesInputs des userStories s'il y a sinon on récupere les timeInputsPerTheme
        const times = userStories
          ? userStories.flatMap(x => x.timeInputs)
          : timeInputsPerTheme;
        // On regroupes les times par mois x.at pour les projets et x.date_month pour les themes
        const groupTimes = groupBy(
          // On filtre les times par THEME si il n'y a pas de userStories
          times.filter(ti => {
            if (!userStories) {
              return ti.themeId === id;
            }
            return true;
          }),
          x => {
            if (!userStories) {
              return moment(x.date_month).format("MMM");
            }
            return moment(x.at).format("MMM");
          }
        );

        // on créer à la volée les colonnes mois
        const timePerMonth = monthes.map(month => {
          const groupByUserType = groupBy(groupTimes[month.name], x =>
            (x.userId && userLookup[x.userId].isExternal) || x.isExternal
              ? "external"
              : "internal"
          );
          return {
            ...month,
            external: financialReducer(groupByUserType["external"] || []),
            internal: financialReducer(groupByUserType["internal"] || [])
          };
        });
        // Pour chaque mois on ajoute les valeurs times des internal et external
        timePerMonth.forEach(time => {
          total[time.name].internal += time.internal;
          total[time.name].external += time.external;
        });

        return {
          name,
          isSpecific: userStories
            ? isSpecific
              ? "x"
              : ""
            : isValued
            ? "x"
            : "",
          estimatedDays,
          startDate,
          endDate,
          timePerMonth
        };
      }
    );
  };

  const csvData = generateDataForCSV(projects, totalProjects);
  // On initialises les entêtes avec leur clé et on leur attribue un nom "Label"
  const headers = [
    { label: "Project", key: "name" },
    { label: "Specific", key: "isSpecific" },
    { label: "Estimated days", key: "estimatedDays" },
    { label: "From", key: "startDate" },
    { label: "To", key: "endDate" }
  ];
  // Pour chaque mois, je créer deux colums headers avec nom du mois + Internal  et  nom du mois + External
  csvData[0].timePerMonth.forEach((timePerMonth, index) => {
    headers.push({
      label: `${timePerMonth.name} Internal`,
      key: `timePerMonth[${index}].internal`
    });
    headers.push({
      label: `${timePerMonth.name} External`,
      key: `timePerMonth[${index}].external`
    });
  });

  // Je crée une ligne total
  csvData.push({
    name: "Total",
    timePerMonth: csvData[0].timePerMonth.map(timePerMonth => {
      return totalProjects[timePerMonth.name];
    })
  });
  // On crée une ligne vide pour espacer les projets et les themes
  csvData.push({});
  // On crée la ligne entetes pour les themes
  csvData.push({
    name: "Theme",
    isSpecific: "Valued",
    timePerMonth: csvData[0].timePerMonth.map(timePerMonth => {
      return {
        external: `${timePerMonth.name} External`,
        internal: `${timePerMonth.name} Internal`
      };
    })
  });
  // je genere les datas pour les themes
  const themesData = generateDataForCSV(themes, totalThemes);
  themesData.forEach(data => {
    csvData.push(data);
  });

  csvData.push({
    name: "Total",
    timePerMonth: themesData[0].timePerMonth.map(timePerMonth => {
      return totalThemes[timePerMonth.name];
    })
  });
  csvData.push({});
  // On ajoute une ligne total des projets et themes et on affecte les totaux
  csvData.push({
    name: "Total projects + themes",
    timePerMonth: themesData[0].timePerMonth.map(timePerMonth => {
      return {
        internal:
          totalThemes[timePerMonth.name].internal +
          totalProjects[timePerMonth.name].internal,
        external:
          totalThemes[timePerMonth.name].external +
          totalProjects[timePerMonth.name].external
      };
    })
  });
  return { headers, csvData };
}
