export const statefilterReducer = (state, action) => {
  const { type, payload } = action;

  const o = {};
  payload.forEach(selectedStatus => {
    o[selectedStatus] = true;
  });

  const result = {
    ...{ CREATION: false, DOING: false, DONE: false },
    ...o
  };

  switch (type) {
    case "TOGGLE":
      return result;
    default:
      throw new Error(`Unsupported statusFilterReducer type ${type}`);
  }
};
