import useAxios from 'axios-hooks';
import * as moment from 'moment';
import Axios from 'axios';
import config from '../../configuration/configuration';
import { getQueryFromArray } from '../../helper/queryFromArrayHelper';
import { toast } from 'react-toastify';

const url = config.projectManagerApiUrl;
const secure = '/api/secure';

function getWithAuth(route) {
  return {
    url: `${url}${route}`,
    headers: {
      'x-access-token': localStorage.getItem('keycloak-token') || '',
    },
  };
}

function deleteWithAuth(route) {
  return {
    method: 'DELETE',
    url: `${url}${route}`,
    headers: {
      'x-access-token': localStorage.getItem('keycloak-token') || '',
    },
  };
}

export function useProjects(relations, statusToFilter) {
  return useAxios(
    getWithAuth(
      `/api/secure/projects?${getQueryFromArray(
        'relations',
        relations,
      )}&${getQueryFromArray('excludestatus', statusToFilter)}`,
    ),
  );
}

export function useProject(id) {
  return useAxios(getWithAuth(`${secure}/projects/${id}`));
}

export function useUserStory(id, withTimeInputs = false) {
  return useAxios(
    getWithAuth(
      `${secure}/user-stories/${id}?withTimeInputs=${withTimeInputs}`,
    ),
  );
}

export function saveUserStory(userStory, projectId) {
  return Axios.post(
    `${url}${secure}/user-stories`,
    {
      ...userStory,
      project: {
        id: projectId,
      },
    },
    {
      headers: {
        'x-access-token': localStorage.getItem('keycloak-token') || '',
      },
    },
  ).then((response) =>
    response.status !== 201
      ? toast.error('Error while saving user story', {
          autoClose: false,
        })
      : '',
  );
}

export function saveProject(project) {
  return Axios.post(
    `${url}${secure}/projects`,
    {
      ...project,
    },
    {
      headers: {
        'x-access-token': localStorage.getItem('keycloak-token') || '',
      },
    },
  )
    .catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          autoClose: false,
        });
      }
    })
    .then((response) =>
      response && response.status && response.status === 201
        ? project.deletedAt
          ? toast.info('Project deleted')
          : toast.info('Project saved')
        : toast.error('Error while saving the project', {
            autoClose: false,
          }),
    );
}

export function saveTimeInput(timeInput) {
  return Axios.post(
    `${url}${secure}/time-inputs`,
    {
      ...timeInput,
      user: {
        id: timeInput.userId,
      },
      theme: {
        id: timeInput.themeId,
      },
      customer: {
        id: timeInput.customerId,
      },
      source: {
        id: timeInput.sourceId || null,
      },
      userStory: {
        id: timeInput.userStoryId || null,
      },
      application: {
        id: timeInput.applicationId || null,
      },
    },
    {
      headers: {
        'x-access-token': localStorage.getItem('keycloak-token') || '',
      },
    },
  ).then(
    (response) =>
      response.status === 201
        ? toast.info('Time Entry saved')
        : toast.error('Error while saving the Time Entry'),
    {
      autoClose: false,
    },
  );
}

export function deleteTimeInput(id) {
  return Axios(deleteWithAuth(`${secure}/time-inputs/${id}`));
}

export function useUsers() {
  return useAxios(getWithAuth(`${secure}/users`));
}

export function useThemes() {
  return useAxios(getWithAuth(`${secure}/themes`));
}

export function useCustomers() {
  return useAxios(getWithAuth(`${secure}/customers`));
}

export function useSources() {
  return useAxios(getWithAuth(`${secure}/sources`));
}

export function useApplications() {
  return useAxios(getWithAuth(`${secure}/applications`));
}

function dateToString(strDate) {
  return moment(strDate).toISOString();
}

export function useTimeInputs(options = {}) {
  const { from, to, userStoryId, userId } = options;

  const queries = [];
  if (userStoryId) queries.push(`userStoryId=${userStoryId}`);
  if (userId) queries.push(`userId=${userId}`);
  if (from && moment(from).isValid())
    queries.push(`from=${dateToString(from)}`);
  if (to && moment(to).isValid()) queries.push(`to=${dateToString(to)}`);

  return useAxios(getWithAuth(`${secure}/time-inputs?${queries.join('&')}`));
}

export function useTimeInputsPerThemes(options) {
  const { from, to } = options;
  const queries = [];
  if (from && moment(from).isValid())
    queries.push(`from=${dateToString(from)}`);
  if (to && moment(to).isValid()) queries.push(`to=${dateToString(to)}`);

  return useAxios(
    getWithAuth(`${secure}/time-inputs/themes?${queries.join('&')}`),
  );
}
