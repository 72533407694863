import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Formik } from "formik";
import ModaleDelete from "../List/ModaleDelete";
// import { toast } from 'react-toastify'

export default function AddUserStory({
  open,
  handleCancel,
  handleSave,
  handleDelete,
  userStory,
  allUs
}) {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {userStory ? userStory.name : "New User story"}
      </DialogTitle>
      <Formik
        initialValues={
          userStory || {
            name: "",
            description: "",
            acceptanceCriteria: "",
            estimatedDays: 0,
            isDone: false,
            order: (allUs || []).length + 1,
            isNew: true
          }
        }
        // validationSchema={validationSchema}
        render={({ values, handleChange, isSubmitting }) => {
          const {
            name,
            description,
            acceptanceCriteria,
            estimatedDays,
            isDone
          } = values;

          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                handleSave(values);
              }}
            >
              <DialogContent>
                <DialogContentText>
                  Please fill in the following informations
                </DialogContentText>

                <TextField
                  id="name"
                  name="name"
                  label="name"
                  value={name}
                  onChange={handleChange}
                  fullWidth
                  required
                  autoFocus
                  margin="normal"
                />
                <TextField
                  id="description"
                  name="description"
                  label="description"
                  onChange={handleChange}
                  value={description}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="estimatedDays"
                  name="estimatedDays"
                  label="estimatedDays"
                  type="number"
                  onChange={handleChange}
                  value={estimatedDays}
                  fullWidth
                  margin="normal"
                  inputProps={{ min: "0", step: "0.01" }}
                />
                <TextField
                  id="acceptanceCriteria"
                  name="acceptanceCriteria"
                  label="acceptanceCriteria"
                  value={acceptanceCriteria}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={3}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isDone"
                      name="isDone"
                      label="Done"
                      onChange={handleChange}
                      checked={isDone}
                    />
                  }
                  label="Done"
                />
              </DialogContent>
              <DialogActions>
                {userStory && (
                  <ModaleDelete
                    userStory={userStory}
                    handleDelete={handleDelete}
                  />
                )}
                <Button onClick={handleCancel} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
}
