import React from 'react';
import SelectBox from 'devextreme-react/select-box';

export const UserSelect = ({ users, selectedUser, setSelectedUser }) => {
  return (
    <SelectBox
      value={selectedUser}
      dataSource={users}
      valueExpr="id"
      displayExpr="trigram"
      searchEnabled
      onValueChanged={({ value }) => setSelectedUser(value)}
      placeholder="Visualiser les temps d'un utilisateur"
    />
  );
};
