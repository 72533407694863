import React, { useMemo, useState } from 'react';
import DevExScheduler, { Resource } from 'devextreme-react/scheduler';
import {
  useProjects,
  useTimeInputs,
  useUsers,
} from '../Project/ProjectsApi.js';
import { useLocalStorage } from 'react-use';
import { ProjectSelect } from './selects/project-select.js';
import { UserSelect } from './selects/user-select.js';
import moment from 'moment';
import { useFormattedTimeInputs } from './lib.js';
import randomColor from 'randomcolor';
import { DataCell, DataCellTooltip } from './DataCell.jsx';
import { Header } from './Header.jsx';

export const Scheduler = () => {
  const HOURS_PER_WEEK = 39;
  const MINUTES_PER_WEEK = 39 * 60;
  const currentDate = new Date();
  const [selectedProjectIds, setSelectedProjectIds] = useLocalStorage(
    'PM-scheduler-starred-projects',
    [],
  );

  const [selectedUser, setSelectedUser] = useLocalStorage(
    'PM-scheduler-selected-user',
    null,
  );

  const [fromToDates, setFromToDates] = useState({
    from: moment(currentDate).startOf('week'),
    to: moment(currentDate).endOf('week'),
  });

  const [{ loading: loadingProjects, data: projects }] = useProjects([
    'userStories',
  ]);
  const [{ loading: loadingUsers, data: users }] = useUsers();
  const [{ loading: loadingTimeInputs, data: timeInputs }] = useTimeInputs({
    from: fromToDates.from,
    to: fromToDates.to,
    userId: selectedUser,
  });

  const projectsWithSaisieLibre = useMemo(() => {
    if (!projects?.length) return [];
    return [
      ...projects,
      {
        id: 'OTHER',
        name: 'Mantis / Saisie Libre',
      },
    ].map((x) => ({
      ...x,
      color: randomColor({
        luminosity: 'dark',
        seed: x.name,
      }),
    }));
  }, [projects]);

  const formattedTimeInputs = useFormattedTimeInputs(
    timeInputs,
    projectsWithSaisieLibre,
  );

  if (loadingProjects || loadingUsers || loadingTimeInputs)
    return <p>loading ...</p>;

  const selectedProjects = projectsWithSaisieLibre.filter((x) =>
    selectedProjectIds.includes(x.id),
  );

  const getAddedTimeInputsForDate = (date) => {
    const timeInputsForDate = timeInputs.filter((x) =>
      moment(x.at).isBetween(
        moment(date).startOf('day'),
        moment(date).endOf('day'),
      ),
    );
    const minutes = timeInputsForDate.reduce((sum, { minutes }) => {
      return sum + minutes;
    }, 0);
    return Math.round((minutes / 60) * 10) / 10;
  };

  const totalAmountOfMinutesEnteredPerWeek = timeInputs.reduce(
    (sum, { minutes }) => sum + minutes,
    0,
  );

  return (
    <div>
      <ProjectSelect
        projects={projectsWithSaisieLibre}
        selectedProjects={selectedProjectIds}
        setSelectedProjects={setSelectedProjectIds}
      />
      <UserSelect
        users={users}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />

      <DevExScheduler
        timeZone="Europe/Paris"
        dataSource={formattedTimeInputs}
        views={['timelineWorkWeek']}
        defaultCurrentView="timelineWorkWeek"
        currentDate={fromToDates.from}
        groups={['projectId']}
        firstDayOfWeek={1}
        startDayHour={8}
        endDayHour={18}
        showAllDayPanel={false}
        crossScrollingEnabled
        cellDuration={600}
        dateCellComponent={({ data: { date } }) => (
          <Header date={date} enteredHours={getAddedTimeInputsForDate(date)} />
        )}
        timeCellTemplate={() => null}
        onCurrentDateChange={(e) =>
          setFromToDates({
            from: moment(e).startOf('week'),
            to: moment(e).endOf('week'),
          })
        }
        appointmentRender={DataCell}
        appointmentTooltipComponent={DataCellTooltip}
      >
        <Resource
          fieldExpr="projectId"
          allowMultiple={false}
          displayExpr="name"
          dataSource={selectedProjects}
          label="Project"
          useColorAsDefault
        />
      </DevExScheduler>
      <h2 style={{ textAlign: 'center' }}>
        <span>
          {totalAmountOfMinutesEnteredPerWeek} \ {MINUTES_PER_WEEK} minutes
          saisies
        </span>
        <span>
          {totalAmountOfMinutesEnteredPerWeek >= MINUTES_PER_WEEK ? '✔️' : '❌'}
        </span>
        <br />
        <span>
          ({Math.round((totalAmountOfMinutesEnteredPerWeek / 60) * 10) / 10} /{' '}
          {HOURS_PER_WEEK} heures)
        </span>
      </h2>
    </div>
  );
};
