import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import {  getSanitizedLocalStorageValue } from "../../../helper"
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import moment from "moment";
import ThemeSelect from "../selects/ThemeSelect";
import UserSelect from "../selects/UserSelect";
import CustomerSelect from "../selects/CustomerSelect";
import SourceSelect from "../selects/SourceSelect";
import ApplicationSelect from "../selects/ApplicationSelect";

export default function AddTimeInput({
  open,
  handleCancel,
  handleSave,
  timeInput,
  displayContact
}) {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">
        {timeInput ? "Edit Time input" : "New Time input"}
      </DialogTitle>
      <Formik
        onSubmit={values => {
          window.localStorage.setItem("sourceId", values.sourceId);
          window.localStorage.setItem("themeId", values.themeId);
          window.localStorage.setItem("userId", values.userId);
          window.localStorage.setItem("customerId", values.customerId);
          window.localStorage.setItem("applicationId", values.applicationId);
          handleSave(values);
        }}
        initialValues={
          timeInput || {
            at: moment().toISOString(),
            description: "",
            contact: null,
            minutes: "",
            themeId: getSanitizedLocalStorageValue("themeId"),
            userId: getSanitizedLocalStorageValue("userId"),
            sourceId: getSanitizedLocalStorageValue("sourceId"),
            applicationId: getSanitizedLocalStorageValue("applicationId"),
            customerId: getSanitizedLocalStorageValue("customerId") 
          }
        }
        validationSchema={validationSchema}
        render={({
          values,
          handleChange,
          isSubmitting,
          onBlur,
          errors,
          touched,
          handleSubmit
        }) => {
          const {
            at,
            minutes,
            description,
            contact,
            themeId,
            applicationId,
            userId,
            customerId,
            sourceId
          } = values;

          const handleDateChange = name => date => {
            handleChange({ target: { name, value: date } });
          };

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  Please fill in the following informations
                </DialogContentText>

                <TextField
                  id="minutes"
                  name="minutes"
                  label="Minutes"
                  type="number"
                  onChange={handleChange}
                  value={minutes}
                  fullWidth
                  margin="normal"
                  autoFocus
                  error={touched.minutes && errors.minutes}
                  helperText={touched.minutes && errors.minutes}
                  inputProps={{ min: "0", step: "1" }}
                />
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  onChange={handleChange}
                  value={description}
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  error={touched.description && errors.description}
                  helperText={touched.description && errors.description}
                />
                {displayContact && (
                  <TextField
                    id="contact"
                    name="contact"
                    label="Contact"
                    onChange={handleChange}
                    value={contact}
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    error={touched.contact && errors.contact}
                    helperText={touched.contact && errors.contact}
                  />
                )}
                <ThemeSelect
                  id="themeId"
                  name="themeId"
                  label="Theme"
                  onChange={handleChange}
                  value={themeId}
                  fullWidth
                  error={touched.themeId && errors.themeId}
                  helperText={touched.themeId && errors.themeId}
                />
                <UserSelect
                  id="userId"
                  name="userId"
                  label="User"
                  onChange={handleChange}
                  value={userId}
                  fullWidth
                  error={touched.userId && errors.userId}
                  helperText={touched.userId && errors.userId}
                />
                <ApplicationSelect
                  id="applicationId"
                  name="applicationId"
                  label="Application"
                  onChange={handleChange}
                  value={applicationId}
                  fullWidth
                  error={touched.applicationId && errors.applicationId}
                  helperText={touched.applicationId && errors.applicationId}
                />
                <CustomerSelect
                  id="customerId"
                  name="customerId"
                  label="Customer"
                  onChange={handleChange}
                  value={customerId}
                  fullWidth
                  error={touched.customerId && errors.customerId}
                  helperText={touched.customerId && errors.customerId}
                />
                <SourceSelect
                  id="sourceId"
                  name="sourceId"
                  label="Source"
                  onChange={handleChange}
                  value={sourceId}
                  fullWidth
                  error={touched.sourceId && errors.sourceId}
                  helperText={touched.sourceId && errors.sourceId}
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY HH:mm"
                    margin="normal"
                    id="at"
                    name="at"
                    label="At"
                    value={at}
                    onChange={handleDateChange("at")}
                    KeyboardButtonProps={{
                      "aria-label": "At"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      ></Formik>
    </Dialog>
  );
}

const validationSchema = Yup.object().shape({
  minutes: Yup.number()
    .label("Minutes")
    .required(),
  description: Yup.string()
    .label("Description")
    .min(3)
    .max(255)
    .required(),
  contact: Yup.string().nullable()
    .label("Contact")
    .min(3)
    .max(255),
  userId: Yup.string()
    .label("User")
    .nullable()
    .required(),
  customerId: Yup.string()
    .label("Customer")
    .nullable()
    .required(),
  themeId: Yup.string()
    .label("Theme")
    .nullable()
    .required()
});
