import React from "react";
import { MenuItem } from "@material-ui/core";
import Select from "../../lib/Select";

export default function StatusSelect(props) {
  const status = [
    { name: 'CREATION' },
    { name: 'DOING' },
    { name: 'DONE' }
  ];

  return (
    <Select {...props}> 
      {" "}
      {(status || []).map(({ name }) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}
