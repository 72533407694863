import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useProject, saveUserStory } from "../ProjectsApi";
import Loading from "../../lib/Loading";
import Error from "../../lib/Error";
import ReactMarkdown from "react-markdown";
import {
  Breadcrumbs,
  Typography,
  Paper,
  Link as MLink
} from "@material-ui/core";
import PageTitle from "../../lib/PageTitle";
import AddUserStory from "./AddUserStory";
import { useToggle } from "react-use";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { calculRealCompletion } from "../../../helper/realCompletionHelper";
import { calculTimeSpentonUS } from "../../../helper/timeSpentOnUSHelper";
import {
  getBoardLists,
  getUserBoards,
  createBoard,
  createCard,
  getCards,
  updateCards,
  getLabels,
  addMember
} from "../../Project/TrelloApi";
import * as R from "ramda";
import { toast } from "react-toastify";

export default function Backlog() {
  const { id } = useParams();
  const [{ loading, error, data: project }, refresh] = useProject(id);
  const [openCreateModal, toggleCreateModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState(null);
  if (loading && !project) return <Loading />;
  if (error) return <Error error={error} />;
  const {
    totalDays,
    doneDays,
    remainingDays,
    percentage
  } = calculRealCompletion(project);

  const handleSave = values => {
    saveUserStory(values, project.id)
      .then(() => {
        toggleCreateModal(false);
        refresh();
      })
      .catch(err => {
        alert(err.message);
      });
  };

  const handleChangeOrderUp = value => {
    const currentUs = project.userStories.find(
      project => project.id === value,
      project.userStories
    );
    if (currentUs.order > 1) {
      const currentUsUpdate = { ...currentUs, order: currentUs.order - 1 };
      handleSave(currentUsUpdate);
    }
  };
  const handleChangeOrderDown = value => {
    const currentUs = project.userStories.find(
      project => project.id === value,
      project.userStories
    );
    if (currentUs.order < project.userStories.length) {
      const currentUsUpdate = { ...currentUs, order: currentUs.order + 1 };
      handleSave(currentUsUpdate);
    }
  };

  let currentProject = {
    name: project.name
  };

  async function exportToTrello() {
    try {
      // const adsId = "5ce8e00dbca93c2ea6d7ddae";
      // const team = await getMember(adsId);
      // const users = ["5d6cbf5cdbf1b52ae9352baa"]; //Guillaume ID

      // Users dans l'ordre des id dessous 1)SEB  2) GMS 3) ABT 4) VPT
      const users = [
        "5cebf3428b391e6eac4f4bc8",
        "5d6cbf5cdbf1b52ae9352baa",
        "5d22fd3aa5e7343699766578",
        "5ce8de998fa4010183b80778"
      ];

      const displayEstimatedDays = item => {
        const estimatedDays = item === 0 ? "" : "(" + item + ")";
        return estimatedDays;
      };

      const displaySpentDays = us => {
        const item = calculTimeSpentonUS(us, project.ratioHrsDays);
        return item === 0 ? "" : "[" + item + "]";
      };

      // Récupération de l'id du board Trello
      const getCurrentBoard = async () => {
        const allBoard = await getUserBoards("me");
        const currentBoardId = allBoard
          .filter(res => res.name === currentProject.name)
          .map(({ id }) => id);
        return currentBoardId;
      };

      // Récupérer la liste des listes du Board courant
      const getCurrentBoardList = async () => {
        const currentBoardId = await getCurrentBoard();
        const currentBoardList = await getBoardLists(currentBoardId);
        return currentBoardList;
      };

      const result = await getUserBoards("me");
      const getUsLabels = async currentBoard => {
        const allLabels = await getLabels(currentBoard);
        const usLabel = R.filter(label => label.name === "US", allLabels);
        return usLabel;
      };

      // Clean le name de l'us reçu de Trello (enlever les () && [] si ils existent)
      const cleanName = card => {
        const regex = /(\(\d*\.?\d\))|(\[\d*\.?\d\])/g;
        const cleanCardName = card.name.replace(regex, "");

        return cleanCardName;
      };

      const generateCard = async (listId, userStory, label) => {
        return await createCard(
          listId,
          displayEstimatedDays(userStory.estimatedDays) +
            userStory.name +
            displaySpentDays(userStory.timeInputs),
          userStory.description +
            (userStory.acceptanceCriteria === ""
              ? ""
              : "\n" +
                "\n" +
                "###*Acceptance criteria*\n" +
                userStory.acceptanceCriteria.replace(/^/gm, "- ") +
                "\n"),
          userStory.order,
          label
        );
      };

      // Création du board Trello si inexistant
      if (!result.some(res => res.name === currentProject.name)) {
        await createBoard(currentProject.name);

        let labelUs = await getUsLabels(await getCurrentBoard());
        let currentBoardList = await getCurrentBoardList();
        users.forEach(async user => {
          await addMember(await getCurrentBoard(), user);
        });
        const createBoardCall = project.userStories.map(async userStories => {
          return await generateCard(
            currentBoardList[0].id,
            userStories,
            labelUs[0].id
          );
        });
        Promise.all(createBoardCall).then(result => {
          const success = result.every(res => res.ok);
          result.length !== 0 &&
            toast(
              success
                ? `${result.length} Cards created with success`
                : "Failed to created cards",
              {
                type: success ? toast.TYPE.INFO : toast.TYPE.ERROR,
                autoClose: 2000
              }
            );
        });
      } else {
        const allCards = await getCards(await getCurrentBoard());
        const nameCleaned = allCards.map(current => cleanName(current));
        const nameUs = project.userStories.map(current => current.name);
        const diff = R.difference(nameUs, nameCleaned);
        const allList = await getCurrentBoardList();
        const backlog = R.filter(list => list.name === "Backlog", allList);
        const labelUs = await getUsLabels(await getCurrentBoard());

        if (diff.length !== 0) {
          const createCardCall = diff.map(async card => {
            const newCard = project.userStories.filter(
              current => current.name === card
            );
            return await generateCard(backlog[0].id, newCard[0], labelUs[0].id);
          });
          Promise.all(createCardCall).then(result => {
            const success = result.every(res => res.ok);
            toast(
              success
                ? `${result.length} Cards created with success`
                : "Failed to created cards",
              {
                type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
                autoClose: 2000
              }
            );
          });
        } else {
          const cardCalls = allCards.map(async card => {
            let labelUs = await getUsLabels(await getCurrentBoard());
            const matchName = project.userStories.filter(
              current => current.name === cleanName(card)
            );

            if (matchName.length === 1) {
              return await updateCards(
                card.idList,
                card.id,
                displayEstimatedDays(matchName[0].estimatedDays) +
                  matchName[0].name +
                  displaySpentDays(matchName[0].timeInputs),
                matchName[0].description +
                  (matchName[0].acceptanceCriteria === ""
                    ? ""
                    : "\n" +
                      "\n" +
                      "###*Acceptance criteria*\n" +
                      matchName[0].acceptanceCriteria.replace(/^/gm, "- ") +
                      "\n"),
                card.idList !== backlog[0].id ? card.pos : matchName[0].order,
                labelUs[0].id
              );
            }
          });

          Promise.all(cardCalls).then(result => {
            const success = result.every(res => res.ok);
            toast(
              success
                ? `${result.length} Cards updated with success`
                : "Failed to updated cards",
              {
                type: success ? toast.TYPE.INFO : toast.TYPE.ERROR,
                autoClose: 2000
              }
            );
          });
        }
      }
      return;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    // <Container>
    //   <PagePaper>
    //     <PageTitle>{project.name}</PageTitle>
    //     <SubTitle>User stories</SubTitle>
    <>
      <Breadcrumbs>
        <Link to="/projects" component={MLink}>
          Projects
        </Link>
        <Typography color="textPrimary">{project.name}</Typography>
      </Breadcrumbs>
      <PageTitle>Backlog</PageTitle>
      <p>
        Estimation: {totalDays} days (done: {doneDays}, remaining:{" "}
        {remainingDays} = {percentage}%), US without Estimation:{" "}
        {(project.userStories || []).filter(x => x.estimatedDays === 0).length}
      </p>

      <MaterialTable
        title="User stories"
        columns={[
          {
            field: "name",
            title: "Name",
            render: ({ id, name }) => (
              <Link
                to={`/projects/${project.id}/backlog/${id}`}
                component={MLink}
              >
                {name}
              </Link>
            )
          },
          {
            field: "estimatedDays",
            title: "Estimation (days)",
            type: "numeric"
          },
          { field: "isDone", title: "Done", type: "boolean" },
          {
            field: "",
            title: "Time Spent (days)",
            type: "numeric",
            customSort: (a, b) =>
              calculTimeSpentonUS(a.timeInputs, project.ratioHrsDays) -
              calculTimeSpentonUS(b.timeInputs, project.ratioHrsDays),
            render: ({ timeInputs }) =>
              calculTimeSpentonUS(timeInputs, project.ratioHrsDays)
          },
          { field: "description", title: "Description" },
          {
            field: "order",
            title: "Order",
            type: "numeric",
            defaultSort: "asc",
            hidden: true
          }
        ]}
        data={project.userStories || []}
        options={{
          padding: "dense",
          pageSize: 15,
          actionsColumnIndex: -1,
          draggable: true
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add an user story",
            isFreeAction: true,
            onClick: e => {
              setSelectedId(null);
              toggleCreateModal(true);
            }
          },
          {
            icon: "sync",
            tooltip: "Export to Trello",
            isFreeAction: true,
            onClick: e => {
              exportToTrello();
            }
          },
          {
            icon: "arrow_upward",
            tooltip: "Move US up",
            onClick: (event, rowData) => {
              handleChangeOrderUp(rowData.id);
            }
          },
          {
            icon: "arrow_downward",
            tooltip: "Move US down",
            onClick: (event, rowData) => {
              handleChangeOrderDown(rowData.id);
            }
          },
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setSelectedId(rowData.id);
              toggleCreateModal(true);
            }
          }
        ]}
        detailPanel={rowData => (
          <Paper style={{ padding: 20 }}>
            <ReactMarkdown
              source={
                rowData.acceptanceCriteria ||
                "No acceptance criterias defined for this User Story"
              }
            />
          </Paper>
        )}
      />

      <AddUserStory
        userStory={
          selectedId ? project.userStories.find(x => x.id === selectedId) : null
        }
        allUs={project.userStories}
        open={openCreateModal}
        handleCancel={() => toggleCreateModal(false)}
        handleSave={values => {
          saveUserStory(values, project.id)
            .then(() => {
              toggleCreateModal(false);
              toast("User story saved with success", {
                type: toast.TYPE.INFO,
                autoClose: 2000
              });
              refresh();
            })
            .catch(err => {
              alert(err.message);
            });
        }}
        handleDelete={(date, values) => {
          values.deletedAt = date;
          saveUserStory(values)
            .then(() => {
              toggleCreateModal(false);
              toast("User story deleted with success", {
                type: toast.TYPE.INFO,
                autoClose: 2000
              });
              refresh();
            })
            .catch(err => {
              alert(err.message);
            });
        }}
      />
      {/* 
      <List>
        {project.userStories.map(
          ({ id, name, description, estimatedDays, acceptanceCriteria }) => (
            <ListItem
              key={id}
              onDoubleClick={() => {
                setSelectedId(id);
                toggleCreateModal(true);
              }}
            >
              <ListItemSecondaryAction>
                <TimeIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(`/projects/${project.id}/backlog/${id}/times`);
                  }}
                />
              </ListItemSecondaryAction>
              <ListItemText
                primary={`(${estimatedDays}) ${name}`}
                secondary={description}
              />
            </ListItem>
          )
        )}
      </List>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setSelectedUserStoryId(null);
          toggleCreateModal(true);
        }}
      >
        Add a new User story
      </Button>
      <AddUserStory
        userStory={
          selectedUserStoryId
            ? project.userStories.find(x => x.id === selectedUserStoryId)
            : null
        }
        open={openCreateModal}
        handleCancel={() => toggleCreateModal(false)}
        handleSave={values => {
          saveUserStory(values, project.id)
            .then(() => {
              toggleCreateModal(false);
              refresh();
            })
            .catch(err => {
              alert(err.message);
            });
        }}
      /> */}
    </>
  );
}
