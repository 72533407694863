import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useProjects } from "../Project/ProjectsApi";
import { orderBy } from "lodash";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {" "}
        {parts.map(part => (
          <span
            key={part.text}
            style={{
              fontWeight: part.highlight ? 500 : 400
            }}
          >
            {" "}
            {part.text}{" "}
          </span>
        ))}{" "}
      </div>{" "}
    </MenuItem>
  );
}

// function getSuggestions(value) {
// const inputValue = deburr(value.trim()).toLowerCase();
// const inputLength = inputValue.length;
// let count = 0;

// return inputLength === 0
//   ? []
//   : suggestions.filter(suggestion => {
//       const keep =
//         count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

//       if (keep) {
//         count += 1;
//       }

//       return keep;
//     });
// }

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing(2)
  }
}));

export default function UserStoryAutocompleteSelect({ onSelection }) {
  const [{ data }] = useProjects(["userStories"]);
  const projects = data || [];
  const classes = useStyles();
  const [suggestions, setSuggestions] = React.useState([]);


  const [value, setValue] = useState("");
  const handleSuggestionsFetchRequested = ({ value }) => {
    const suggestions = orderBy(
      projects
        .flatMap(project => [
          {
            projectId: project.id,
            name: project.name
          },
          ...project.userStories.map(us => ({
            projectId: project.id,
            userStoryId: us.id,
            name: `${project.name} > ${us.name}`
          }))
        ])
        .filter(x =>
          x.name.toLowerCase().includes((value || "").toLowerCase())
        ),
      "name"
    );
    setSuggestions(suggestions);
  };
  // ...project.userStories.map(us => ({ projectId: project.id, userStoryId: us.id, name: `${project.name} > ${us.name}`}))
  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue: suggestion => {
      return suggestion.name;
    },
    renderSuggestion,
    onSuggestionSelected: (e, { suggestion }) => {
      onSelection(suggestion);
    }
  };

  return (
    <div className={classes.paper}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          placeholder: 'Project or user story name',
          value: value || '',
          onChange: (e, {newValue}) => {
            setValue(newValue)
          },
          autoFocus: true
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {" "}
            {options.children}{" "}
          </Paper>
        )}
      />{" "}
    </div>
  );
}
