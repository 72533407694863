import React from 'react';
import { durationFormatter } from '../../helper/timeHelper';

export const DataCell = ({ appointmentData: timeEntryData = {} }) => {
  // const { text, startDate, endDate, projectId, minutes } = timeEntryData;
  const { text, minutes } = timeEntryData;
  return (
    <div style={{ height: '500px !important' }}>
      <p>
        <strong>{text}</strong> {durationFormatter(minutes)}
      </p>
    </div>
  );
};

export const DataCellTooltip = ({
  data: { appointmentData: timeEntryData } = {},
}) => {
  // const { text, startDate, endDate, projectId, minutes } = timeEntryData;
  const { text, minutes } = timeEntryData;
  return (
    <div style={{ height: '500px !important' }}>
      <p>
        <strong>{text}</strong> {durationFormatter(minutes)}
      </p>
    </div>
  );
};
