import React, { useState } from "react";
import { Table } from "@material-ui/core";
import FinancialExtractTableHeader from "./FinancialExtractTableHeader";
import FinancialExtractTableBodyThemes from "./FinancialExtractTableBodyThemes";
import FinancialExtractTableBodyProjects from "./FinancialExtractTableBodyProjects";

const FinancialExtractTable = props => {
  const {
    monthes,
    projects,
    financialReducer,
    userLookup,
    userStories,
    themes,
    timeInputsPerTheme
  } = props;
  const [keyToOrder, setKeyToOrder] = useState("name");
  const [order, setOrder] = useState("asc");

  const toggleOrder = keytoSet => {
    setKeyToOrder(keytoSet);
    order === "asc" ? setOrder("desc") : setOrder("asc");
  };

  return (
    <>
      <Table size="small" style={{ marginTop: "20px" }}>
        <FinancialExtractTableHeader {...props} toggleOrder={toggleOrder} />
        {projects ? (
          <FinancialExtractTableBodyProjects
            monthes={monthes}
            projects={projects}
            keyToOrder={keyToOrder}
            financialReducer={financialReducer}
            userLookup={userLookup}
            userStories={userStories}
            order={order}
          />
        ) : (
          <FinancialExtractTableBodyThemes
            monthes={monthes}
            keyToOrder={keyToOrder}
            financialReducer={financialReducer}
            userLookup={userLookup}
            userStories={userStories}
            order={order}
            themes={themes}
            timeInputsPerTheme={timeInputsPerTheme}
          />
        )}
      </Table>
    </>
  );
};

export default FinancialExtractTable;
