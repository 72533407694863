import React from "react";
import PropTypes from "prop-types";

export default function Error({ error }) {
  return <div data-testid="error-panel">Error: ${error.message}</div>;
}

Error.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string.isRequired })
};
