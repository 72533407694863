import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserStory, saveTimeInput, useUsers } from "../ProjectsApi";
import Loading from "../../lib/Loading";
import Error from "../../lib/Error";
import PageTitle from "../../lib/PageTitle";
import { Link as MLink, Breadcrumbs, Typography } from "@material-ui/core";
import { useToggle } from "react-use";
import AddTimeInput from "./AddTimeInput";
import MaterialTable from "material-table";
import DurationFormatter from "../../lib/DurationFormatter";
import moment from "moment";
import { mapValues, keyBy } from "lodash";
import { Link } from "react-router-dom";

export default function UserStoryTimes() {
  const { projectId, userStoryId } = useParams();
  const [{ loading, error, data: userStory }, refresh] = useUserStory(
    userStoryId,
    true
  );
  const [{ data: users }] = useUsers();

  const [openCreateModal, toggleCreateModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState(null);

  if (loading && !userStory) return <Loading />;
  if (error) return <Error error={error} />;

  const userLookup = mapValues(
    keyBy(users, "id"),
    // u => `${u.firstname} ${u.lastname}`
    "trigram"
  );

  return (
    <>
      <Breadcrumbs>
        <Link to="/project" component={MLink}>
          Projects
        </Link>
        <Link to={`/projects/${projectId}/backlog`} component={MLink}>
          User Stories
        </Link>
        <Typography color="textPrimary">{userStory.name}</Typography>
      </Breadcrumbs>
      <PageTitle>{userStory.name}</PageTitle>
      <p>
        {userStory.timeInputs.reduce((total, ti) => total + ti.minutes, 0)}{" "}
        {" minutes"} spent
      </p>
      <MaterialTable
        title="Time entries"
        columns={[
          {
            field: "at",
            title: "At",
            defaultSort: "desc",
            render: ({ at }) => moment(at).format("YY-MM-DD HH:mm")
          },
          { field: "userId", title: "By", lookup: userLookup },
          { field: "description", title: "Description" },
          {
            field: "duration",
            title: "Duration",
            render: ({ minutes }) => (
              <DurationFormatter>{minutes}</DurationFormatter>
            )
          }
        ]}
        data={userStory.timeInputs}
        options={{
          padding: "dense",
          pageSize: 10,
          actionsColumnIndex: -1
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add a time entry",
            isFreeAction: true,
            onClick: e => {
              setSelectedId(null);
              toggleCreateModal(true);
            }
          },

          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setSelectedId(rowData.id);
              toggleCreateModal(true);
            }
          }
        ]}
      />
      <AddTimeInput
        timeInput={
          selectedId
            ? userStory.timeInputs.find(x => x.id === selectedId)
            : null
        }
        displayContact={false}
        open={openCreateModal}
        handleCancel={() => toggleCreateModal(false)}
        handleSave={values => {
          values.userStoryId = userStory.id;
          saveTimeInput(values)
            .then(() => {
              toggleCreateModal(false);
              refresh();
            })
            .catch(err => {
              alert(err.message);
            });
        }}
      />
    </>
  );
}
