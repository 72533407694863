import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Projects from './components/Project/List/Projects';
import Backlog from './components/Project/Backlog/Backlog';
import UserStoryTimes from './components/Project/UserStoryTimes/UserStoryTimes';
import TimeInputList from './TimeInputs/TimeInputList';
import FinancialExtract from './components/FinancialExtract/FinancialExtract';
import OverView from './components/Overview/Overview';
import { Scheduler } from './components/Scheduler/Scheduler';

export default function Router() {
  return (
    // <BrowserRouter>
    <Switch>
      <Route path="/projects/:id/backlog" exact>
        <Backlog />
      </Route>
      <Route path="/projects/:projectId/backlog/:userStoryId" exact>
        <UserStoryTimes />
      </Route>
      <Route path="/times" exact>
        <TimeInputList />
      </Route>
      <Route path="/scheduler">
        <Scheduler />
      </Route>
      <Route path="/financial">
        <FinancialExtract />
      </Route>
      <Route path="/overview">
        <OverView />
      </Route>
      <Route path="/">
        <Projects />
      </Route>
    </Switch>
    // </BrowserRouter>
  );
}
