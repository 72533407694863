import React from "react";
import {
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableFooter
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { orderBy, groupBy } from "lodash";
import moment from "moment";

import FinancialExtractTableFooter from "./FinancialExtractTableFooter";

const useStyles = makeStyles({
  firstColProj: {
    width: "35%"
  },
  firstColThem: {
    width: "30%"
  },
  tableCell: {
    width: "12%"
  },
  tableCheckBox: {
    width: "10%"
  }
});

const FinancialExtractBodyProjects = props => {
  const classes = useStyles();
  const {
    monthes,
    projects,
    keyToOrder,
    financialReducer,
    userLookup,
    order
  } = props;

  return (
    <>
      <TableBody>
        {orderBy(
          projects,
          [
            project =>
              typeof project[keyToOrder] === "string"
                ? project[keyToOrder].toLowerCase()
                : project[keyToOrder]
          ],
          order
        ).map(
          ({
            id,
            name,
            isSpecific,
            estimatedDays,
            startDate,
            endDate,
            userStories
          }) => {
            const times = userStories.flatMap(x => x.timeInputs);
            const groupTimes = groupBy(times, x => moment(x.at).format("MMM"));
            return (
              <TableRow key={id}>
                <TableCell className={classes.firstColProj}>{name}</TableCell>
                <TableCell
                  className={classes.tableCheckBox}
                  align="center"
                  padding="checkbox"
                >
                  <Checkbox checked={isSpecific} />
                </TableCell>
                <TableCell className={classes.tableCell}>N/A</TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {estimatedDays}
                </TableCell>
                <TableCell className={classes.tableCell}>N/A</TableCell>
                <TableCell className={classes.tableCell}>{startDate}</TableCell>
                <TableCell className={classes.tableCell}>{endDate}</TableCell>
                {monthes.map(month => {
                  const groupByUserType = groupBy(groupTimes[month.name], x =>
                    userLookup[x.userId].isExternal ? "external" : "internal"
                  );
                  return (
                    <>
                      <TableCell key={`${month.name}`} align="right">
                        {financialReducer(groupByUserType["internal"] || [])}
                      </TableCell>
                      <TableCell key={`${month.name}-external`} align="right">
                        {financialReducer(groupByUserType["external"] || [])}
                      </TableCell>
                    </>
                  );
                })}
              </TableRow>
            );
          }
        )}
      </TableBody>
      <TableFooter>
        <FinancialExtractTableFooter {...props} />
      </TableFooter>
    </>
  );
};

export default FinancialExtractBodyProjects;
