import React, { useState } from 'react';
import {
  useUsers,
  useCustomers,
  useThemes,
  useTimeInputs,
  saveTimeInput,
  useApplications,
  deleteTimeInput,
} from '../components/Project/ProjectsApi';
import Loading from '../components/lib/Loading';
import Error from '../components/lib/Error';
import MaterialTable from 'material-table';
import { keyBy, mapValues } from 'lodash';
import DurationFormatter from '../components/lib/DurationFormatter';
import { Drawer, List, ListItem } from '@material-ui/core';
import { useToggle } from 'react-use';
import AddTimeInput from '../components/Project/UserStoryTimes/AddTimeInput';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

export default function TimeInputList() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [from, setFrom] = useState(moment().startOf('week').toString());
  const [to, setTo] = useState(moment().endOf('week').toString());
  const [openCreateModal, toggleCreateModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  function onClose(confirm) {
    if (confirm) {
      deleteTimeInput(selectedId).then(() => {
        setIsDeleteOpen(false);
        refresh();
      });
    } else {
      setIsDeleteOpen(false);
    }
  }

  const [{ loading: loadingUser, error: errorUser, data: users }] = useUsers();
  const [{ loading: loadingCustomer, error: errorCustomer, data: customers }] =
    useCustomers();
  const [{ loading: loadingTheme, error: errorTheme, data: themes }] =
    useThemes();
  const [
    {
      loading: loadingApplication,
      error: errorApplication,
      data: applications,
    },
  ] = useApplications();

  const [
    { loading: loadingTimeInputs, error: errorTimeInputs, data: timeInputs },
    refresh,
  ] = useTimeInputs({ from, to });

  if (
    !timeInputs &&
    (loadingUser ||
      loadingCustomer ||
      loadingTheme ||
      loadingTimeInputs ||
      loadingApplication)
  )
    return <Loading />;
  if (errorUser || errorCustomer || errorTimeInputs)
    return (
      <Error
        error={
          errorUser ||
          errorCustomer ||
          errorTheme ||
          errorTimeInputs ||
          errorApplication
        }
      />
    );

  const userLookup = mapValues(
    keyBy(users, 'id'),
    // u => `${u.firstname} ${u.lastname}`
    'trigram',
  );
  const themeLookup = mapValues(keyBy(themes, 'id'), 'name');
  const customerLookup = mapValues(keyBy(customers, 'id'), 'trigram');
  const applicationLookup = mapValues(keyBy(applications, 'id'), 'name');
  const title = `Time Entries from ${moment(from).format(
    'DD/MM/YYYY',
  )} to ${moment(to).format('DD/MM/YYYY')}`;

  return (
    <>
      <MaterialTable
        title={title}
        columns={[
          {
            title: 'At',
            field: 'at',
            defaultSort: 'desc',
            type: 'datetime',
            render: (rowData) => moment(rowData.at).format('DD/MM/YYYY HH:mm'),
          },
          { title: 'Theme', field: 'themeId', lookup: themeLookup },
          { title: 'Customer', field: 'customerId', lookup: customerLookup },
          {
            title: 'Application',
            field: 'applicationId',
            lookup: applicationLookup,
          },
          { title: 'By', field: 'userId', lookup: userLookup },
          {
            title: 'Duration',
            field: 'minutes',
            type: 'numeric',
            render: ({ minutes }) => (
              <DurationFormatter>{minutes}</DurationFormatter>
            ),
          },
          { title: 'Description', field: 'description' },
        ]}
        data={timeInputs || []}
        options={{
          padding: 'dense',
          // paging: false,
          pageSize: 15,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add a Time entry',
            isFreeAction: true,
            onClick: (e) => {
              setSelectedId(null);
              toggleCreateModal(true);
            },
          },
          {
            icon: 'search',
            tooltip: 'Show filter panel',
            isFreeAction: true,
            onClick: (e) => {
              setDrawerOpen(true);
            },
          },
          {
            icon: 'edit',
            tooltip: 'Edit Time entry',
            onClick: (event, rowData) => {
              setSelectedId(rowData.id);
              toggleCreateModal(true);
            },
          },
          {
            icon: 'delete',
            tooltip: 'Delete Time entry',
            onClick: (event, rowData) => {
              setSelectedId(rowData.id);
              setIsDeleteOpen(true);
            },
          },
        ]}
      />
      <AddTimeInput
        timeInput={
          selectedId ? timeInputs.find((x) => x.id === selectedId) : null
        }
        displayContact={true}
        open={openCreateModal}
        handleCancel={() => toggleCreateModal(false)}
        handleSave={(values) => {
          saveTimeInput(values)
            .then(() => {
              toggleCreateModal(false);
              refresh();
            })
            .catch((err) => {
              alert(err.message);
            });
        }}
      />
      {/* </PagePaper> */}
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
      >
        <List>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ListItem>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="From"
                value={from}
                onChange={setFrom}
                KeyboardButtonProps={{
                  'aria-label': 'At',
                }}
                fullWidth
              />
            </ListItem>
            <ListItem>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="To"
                value={to}
                onChange={setTo}
                KeyboardButtonProps={{
                  'aria-label': 'At',
                }}
                fullWidth
              />
            </ListItem>
          </MuiPickersUtilsProvider>
        </List>
      </Drawer>
      <DeleteConfirmationDialog open={isDeleteOpen} onClose={onClose} />
    </>
  );
}
