import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  firstColProj: {
    width: "35%"
  },
  firstColThem: {
    width: "30%"
  },
  tableCell: {
    width: "12%"
  },
  tableCheckBox: {
    width: "10%"
  }
});

const FinancialExtractTableHeader = ({
  projects,
  monthes,
  toggleOrder,
  themes
}) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan="7" rowSpan="2" align="center">
          {projects ? "Projects" : themes ? "Themes" : ""}
        </TableCell>
        <TableCell colSpan="100" align="center">
          Hours
        </TableCell>
      </TableRow>
      <TableRow>
        {monthes.map(month => (
          <TableCell colSpan="2" align="center" key={month.name}>
            {month.name}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>
          <TableSortLabel onClick={() => toggleOrder("name")}>
            {projects ? "Project" : themes ? "Theme" : ""}
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.tableCheckBox} align="center">
          <TableSortLabel
            align="center"
            onClick={
              projects
                ? () => toggleOrder("isSpecific")
                : () => toggleOrder("isValued")
            }
          >
            {projects ? "Specific" : themes ? "Valued" : ""}
          </TableSortLabel>
        </TableCell>
        <TableCell>{projects ? "Customer" : ""}</TableCell>
        <TableCell align="right">
          <TableSortLabel
            onClick={projects ? () => toggleOrder("estimatedDays") : ""}
          >
            {projects ? "Estimated Days" : ""}
          </TableSortLabel>
        </TableCell>
        <TableCell>{projects ? "Customer participation" : ""}</TableCell>
        <TableCell>
          <TableSortLabel
            onClick={projects ? () => toggleOrder("startDate") : ""}
          >
            {projects ? "From" : ""}
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            onClick={projects ? () => toggleOrder("endDate") : ""}
          >
            {projects ? "To" : ""}
          </TableSortLabel>
        </TableCell>
        {monthes.map(month => (
          <>
            <TableCell align="right" key={month.name}>
              Internal
            </TableCell>
            <TableCell align="right" key={month.name}>
              External
            </TableCell>
          </>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default FinancialExtractTableHeader;
