import React, { useState, useReducer, useEffect } from 'react';
import { useProjects, saveProject } from '../ProjectsApi';
import Loading from '../../lib/Loading';
import Error from '../../lib/Error';
import { Link, Drawer, ListItem, List, FormControl } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import { useToggle } from 'react-use';
import AddProject from './AddProject';
import { indexBy, prop } from 'ramda';
import { calculTimeSpent } from '../../../helper/timeSpentOnProjectHelper';
import { calculRealCompletion } from '../../../helper/realCompletionHelper';
import StatusSelectFilter from '../selects/StatusSelectFilter';
import { statefilterReducer } from './stateFilterReducer';

const initialState = {
  CREATION: true,
  DOING: true,
  DONE: false,
};

export default function Projects() {
  const [openCreateModal, toggleCreateModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [selectedStatus, dispatchSelectedStatus] = useReducer(
    statefilterReducer,
    initialState,
  );
  const statusToFiltered = Object.keys(selectedStatus).filter(
    (key) => selectedStatus[key] === false,
  );

  const [{ loading, error, data: projects }, refresh] = useProjects(
    ['userStories', 'userStories.timeInputs'],
    statusToFiltered,
  );

  const [dicProject, setDicProject] = useState([]);
  useEffect(() => {
    if (projects) {
      setDicProject(indexBy(prop('id'), projects || []));
    }
  }, [projects]);

  console.log({ projects });
  if (loading || (loading && !projects)) return <Loading />;
  if (error) return <Error error={error} />;
  if (!projects) return null;

  return (
    <>
      <MaterialTable
        title="Projects"
        columns={[
          {
            field: 'name',
            title: 'Name',
            render: ({ id, name }) => (
              <RouterLink component={Link} to={`/projects/${id}/backlog`}>
                {' '}
                {name}{' '}
              </RouterLink>
            ),
          },
          {
            field: '',
            title: 'Time Spent (days)',
            type: 'numeric',
            customSort: (a, b) =>
              calculTimeSpent(a.id, dicProject) -
              calculTimeSpent(b.id, dicProject),
            render: (row) => {
              return calculTimeSpent(row.id, dicProject);
            },
          },
          {
            field: 'estimatedDays',
            title: 'Manual est. (days)',
            type: 'numeric',
          },
          {
            field: 'ratioHrsDays',
            title: 'Ratio hours / days',
            type: 'numeric',
          },
          {
            field: '',
            title: 'Total US est. (days)',
            type: 'numeric',
            customSort: (a, b) =>
              a.userStories.reduce(
                (total, us) =>
                  total + (us.deletedAt === null ? us.estimatedDays : 0),
                0,
              ) -
              b.userStories.reduce(
                (total, us) =>
                  total + (us.deletedAt === null ? us.estimatedDays : 0),
                0,
              ),
            render: ({ userStories }) =>
              userStories.reduce(
                (total, us) =>
                  total + (us.deletedAt === null ? us.estimatedDays : 0),
                0,
              ),
          },
          {
            field: '',
            title: 'US without est.',
            type: 'numeric',
            customSort: (a, b) =>
              a.userStories.filter((us) =>
                us.deletedAt === null ? us.estimatedDays === 0 : null,
              ).length -
              b.userStories.filter((us) =>
                us.deletedAt === null ? us.estimatedDays === 0 : null,
              ).length,
            render: ({ userStories }) =>
              userStories.filter((us) =>
                us.deletedAt === null ? us.estimatedDays === 0 : null,
              ).length,
          },
          {
            field: 'completedPercentage',
            title: 'Est. Completion (%)',
            type: 'numeric',
          },
          {
            field: '',
            title: 'Real Completion (%)',
            type: 'numeric',
            customSort: (a, b) =>
              calculRealCompletion(a).percentage -
              calculRealCompletion(b).percentage,

            render: (row) => {
              const completion = calculRealCompletion(row);
              const { percentage } = completion;
              return percentage;
            },
          },
          {
            field: 'isSpecific',
            title: 'Specific',
            type: 'boolean',
          },
          {
            field: 'startDate',
            title: 'Start',
            type: 'date',
          },
          {
            field: 'endDate',
            title: 'End',
            type: 'date',
          },
        ]}
        data={projects || []}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add a project',
            isFreeAction: true,
            onClick: (e) => {
              setSelectedId(null);
              toggleCreateModal(true);
            },
          },
          {
            icon: 'search',
            tooltip: 'Show filter panel',
            isFreeAction: true,
            onClick: (e) => {
              setDrawerOpen(true);
            },
          },
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              setSelectedId(rowData.id);
              toggleCreateModal(true);
            },
          },
        ]}
        options={{
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 15,
        }}
      />
      <AddProject
        project={selectedId ? projects.find((x) => x.id === selectedId) : null}
        open={openCreateModal}
        handleCancel={() => toggleCreateModal(false)}
        handleSave={(values) => {
          saveProject(values)
            .then(() => {
              toggleCreateModal(false);
              refresh();
            })
            .catch((err) => {
              alert(err.message);
            });
        }}
        handleDelete={(date, values) => {
          values.deletedAt = date;
          saveProject(values)
            .then(() => {
              toggleCreateModal(false);
              refresh();
            })
            .catch((err) => {
              alert(err.message);
            });
        }}
      />{' '}
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
      >
        <FormControl>
          <List>
            <ListItem>
              <StatusSelectFilter
                selectedStatus={selectedStatus}
                onSelectedStatusChanged={dispatchSelectedStatus}
              />{' '}
            </ListItem>{' '}
          </List>{' '}
        </FormControl>{' '}
      </Drawer>{' '}
    </>
  );
}
