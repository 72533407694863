import React from "react";
import { MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import Select from "../../lib/Select";
import moment from 'moment'

export default function MonthSelect(props) {
  const months = moment.months();
  return (
    <Select {...props}>
      {" "}
      {(months || [])
        .map(month => {
          return <MenuItem key={month} value={month}>
          {month}
        </MenuItem>
        }
        )
      }
    </Select>
  );
}

MonthSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
