export default function DurationFormatter({children: duration}) {
  if (!duration) {
      return '';
    }

    if (duration < 60) {
      return `${duration}m`;
    }

    const hours = Math.floor(duration / 60);
    const minutes = Math.floor(duration % 60);

    return `${hours}h${("0" + minutes).slice(-2)}`
}
