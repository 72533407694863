import React from 'react';
import moment from 'moment';

export const Header = ({ date, enteredHours }) => {
  const hoursPerDay = 7.8;

  return (
    <div style={{ height: '100%' }}>
      <span style={{ fontSize: '110%' }}>{moment(date).format('ddd LL')}</span>
      <br />
      <strong style={{ fontSize: '130%' }}>
        {enteredHours} / {hoursPerDay}
      </strong>
      <span>{enteredHours >= hoursPerDay ? '✔️' : '❌'}</span>
    </div>
  );
};
