export const calculTimeSpent = (projectId, dicProject) => {
  const project = dicProject[projectId];
  if (!project) return 0;
  const {
    ratioHrsDays,
    userStories
  } = project;
  const usNotDeleted = userStories.filter(us => us.deletedAt === null);
  let minutesSpentOnProject;
  const reducer = (total, currentTi) => (total += currentTi.minutes);
  const minutesSpentPerUS = usNotDeleted.map(us => {
    const {
      timeInputs
    } = us;
    return timeInputs.length > 0 && timeInputs.reduce(reducer, 0);
  });
  minutesSpentOnProject = minutesSpentPerUS.reduce(
    (total, us) => total + us,
    0
  );
  return Math.round((minutesSpentOnProject / (ratioHrsDays * 60)) * 10) / 10;
};