import React from 'react';
import Router from './Router';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  makeStyles,
  List,
  Divider,
  ListItemIcon,
  ListItem,
  ListItemText,
  Link as MLink,
} from '@material-ui/core';
import ProjectIcon from '@material-ui/icons/WorkOutline';
import TimesIcon from '@material-ui/icons/List';
import FinancialIcon from '@material-ui/icons/AttachMoney';
import { CalendarToday } from '@material-ui/icons/';
import { Link } from 'react-router-dom';
import UserStorySearchModal from './components/search/UserStorySearchModal';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Project manager
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <Link
            to="/projects"
            style={{ textDecoration: 'none' }}
            component={MLink}
          >
            <ListItem button>
              <ListItemIcon>
                <ProjectIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </Link>
          <Link to="/times" component={MLink}>
            <ListItem button>
              <ListItemIcon>
                <TimesIcon />
              </ListItemIcon>
              <ListItemText primary="Time entries" />
            </ListItem>
          </Link>
          <Link to="/financial" component={MLink}>
            <ListItem button>
              <ListItemIcon>
                <FinancialIcon />
              </ListItemIcon>
              <ListItemText primary="Financial extraction" />
            </ListItem>
          </Link>
          <Link to="/scheduler" component={MLink}>
            <ListItem button>
              <ListItemIcon>
                <CalendarToday />
              </ListItemIcon>
              <ListItemText primary="Scheduler (WIP)" />
            </ListItem>
          </Link>
          <Link
            to="/overview"
            style={{ textDecoration: 'none' }}
            component={MLink}
          >
            <ListItem button>
              <ListItemIcon>
                <ProjectIcon />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <main className={classes.content}>
        <UserStorySearchModal />
        <div className={classes.toolbar} />
        <Router />
      </main>
      <CssBaseline />
    </div>
  );
}
