import { toast } from "react-toastify";

const BASE_URL = "https://api.trello.com";
// const API_KEY = '917cc48da0742cfb18ac5c4f3243a8d5'; // key VPT
// const TOKEN = '1d31ca5a3803d55636731a45b55831db120ca1ac63b3ef2af5e7f94db32bc63d'; // token VPT
const API_KEY = "ec45987e70e0d46cdcedd367bd8158e6"; // key ADSOFTWARE
const TOKEN =
  "f4a6d128185866dc153989131b18b659b03b79fb1b7d499011aa45b47a73eddc"; // token ADSOFTWARE

function getAuthString() {
  return `key=${API_KEY}&token=${TOKEN}`;
}

export async function getBoardLists(boardId) {
  const data = await fetch(
    `${BASE_URL}/1/boards/${boardId}/lists?cards=none&card_fields=all&filter=open&${getAuthString()}`
  ).then(res => res.json());
  return data;
}

export async function getUserBoards(member = "me") {
  const data = await fetch(
    `${BASE_URL}/1/members/${member}/boards?${getAuthString()}`
  ).then(res => res.json());

  return data;
}

export async function getBoard(boardId) {
  const data = await fetch(
    `${BASE_URL}/1/boards/${boardId}/actions/?fields=type&${getAuthString()}`
  ).then(res => res.json());
  return data;
}

export function createBoard(name) {
  return fetch(
    `${BASE_URL}/1/boards/?name=${encodeURIComponent(
      name
    )}&defaultLabels=true&defaultLists=false&idBoardSource=5da6deaa3eb322537d8a5b9f&keepFromSource=none&prefs_permissionLevel=private&prefs_voting=disabled&prefs_comments=members&prefs_invitations=members&prefs_selfJoin=true&prefs_cardCovers=true&prefs_background=grey&prefs_cardAging=regular&${getAuthString()}`,
    {
      method: "POST"
    }
  ).then(response =>
    response.status === 200
      ? toast.success("Board Created", {autoClose: 2000, type: toast.TYPE.INFO})
      : toast.warn("Error while Board Creation")
  );
}

export async function createCard(listId, name, desc, order, labelId) {
  return await fetch(
    `${BASE_URL}/1/cards?idList=${listId}&name=${encodeURIComponent(
      name
    )}&desc=${encodeURIComponent(
      desc
    )}&pos=${order}&idLabels=${labelId}&${getAuthString()}`,
    {
      method: "POST"
    }
  )
}

export async function getCards(boardId) {
  const data = await fetch(
    `${BASE_URL}/1/board/${boardId}/cards?fields=id,name,desc,idList,pos&${getAuthString()}`
  ).then(res => res.json());
  return data;
}

export async function getLabels(boardId) {
  const data = await fetch(
    `${BASE_URL}/1/board/${boardId}/labels?fields=all&${getAuthString()}`
  ).then(res => res.json());
  return data;
}

export async function updateCards(listId, cardId, name, desc, order, labelId) {
  return await fetch(
    `${BASE_URL}/1/cards/${cardId}?name=${encodeURIComponent(
      name
    )}&desc=${encodeURIComponent(
      desc
    )}&idList=${listId}&pos=${order}&idLabels=${labelId}&${getAuthString()}`,
    {
      method: "PUT"
    }
  )
}

export async function addMember(boardId, memberId) {
  return await fetch(
    `${BASE_URL}/1/boards/${boardId}/members/${memberId}?type=admin&allowBillableGuest=false&${getAuthString()}`,
    {
      method: "PUT"
    }
  );
}

export async function getMember(teamId) {
  const data = await fetch(
    `${BASE_URL}/1/organizations/${teamId}/members?${getAuthString()}`
  ).then(res => res.json());
  return data;
}
