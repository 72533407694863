import moment from 'moment';

export const useFormattedTimeInputs = (timeInputs, projects) => {
  if (!timeInputs?.length || !projects?.length) return [];
  return timeInputs.map((timeInput) => ({
    text: timeInput.description,
    projectId:
      projects?.find((x) =>
        x.userStories?.find((y) => y?.id === timeInput?.userStoryId),
      )?.id || 'OTHER',
    startDate: moment(timeInput.at),
    endDate: moment(timeInput.at).add(timeInput.minutes, 'minutes'),
    minutes: timeInput.minutes,
  }));
};
