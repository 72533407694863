import React from "react";
import { MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { useCustomers } from "../ProjectsApi";
import Select from "../../lib/Select";

export default function CustomerSelect(props) {
  const [{ data: customers }] = useCustomers();

  return (
    <Select {...props}>
      {" "}
      {(customers || [])
        .sort((a, b) => a.trigram.localeCompare(b.trigram))
        .map(({ id, trigram, name }) => (
          <MenuItem key={id} value={id}>
            {trigram} - {name}
          </MenuItem>
        ))}
    </Select>
  );
}

CustomerSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
