import React from "react";
import { useSources } from "../ProjectsApi";
import { MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import Select from "../../lib/Select";

export default function SourceSelect(props) {
  const [{ data: sources }] = useSources();

  return (
    <Select {...props}>
      {" "}
      {(sources || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
    </Select>
  );
}

SourceSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
