import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import moment from "moment";
import { groupBy } from "lodash";

const FinancialExtractTableFooter = ({
  monthes,
  userStories,
  userLookup,
  financialReducer,
  timeInputsPerTheme,
  themes
}) => {
  return (
    <TableRow>
      <TableCell colSpan="7">Total</TableCell>
      {monthes.map(month => {
        const times = userStories.flatMap(x => x.timeInputs);
        let groupTimes;
        themes
          ? (groupTimes = groupBy(timeInputsPerTheme, x =>
              moment(x.date_month).format("MMM")
            ))
          : (groupTimes = groupBy(times, x => moment(x.at).format("MMM")));
        const groupByUserType = groupBy(groupTimes[month.name], x =>
          themes
            ? x.isExternal
              ? "external"
              : "internal"
            : userLookup[x.userId].isExternal
            ? "external"
            : "internal"
        );
        return (
          <>
            <TableCell key={`${month.name}`} align="right">
              {financialReducer(groupByUserType["internal"] || [])}
            </TableCell>
            <TableCell key={`${month.name}-external`} align="right">
              {financialReducer(groupByUserType["external"] || [])}
            </TableCell>
          </>
        );
      })}
    </TableRow>
  );
};

export default FinancialExtractTableFooter;
