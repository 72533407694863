import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { groupBy } from "lodash";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  row: {
    height: "120px"
  },
  table: {
    marginTop: "20px"
  },
  cellTitle: {
    width: "70%",
    color: "#f50057"
  },
  cellTotal: {
    width: "5%",
    color: "#f50057"
  }
});

const FinancialExtractTotals = ({
  monthes,
  userStories,
  userLookup,
  financialReducer,
  timeInputsPerTheme
}) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell colSpan="7" align="right" className={classes.cellTitle}>
        TOTALS PROJECTS + THEMES
      </TableCell>
      {monthes.map(month => {
        const times = userStories.flatMap(x => x.timeInputs);
        const groupTimesProjects = groupBy(times, x =>
          moment(x.at).format("MMM")
        );
        const groupTimesThemes = groupBy(timeInputsPerTheme, x =>
          moment(x.date_month).format("MMM")
        );
        const groupByUserTypeProjects = groupBy(
          groupTimesProjects[month.name],
          x => (userLookup[x.userId].isExternal ? "external" : "internal")
        );
        const groupByUserTypeThemes = groupBy(groupTimesThemes[month.name], x =>
          x.isExternal ? "external" : "internal"
        );
        return (
          <>
            <TableCell
              className={classes.cellTotal}
              key={`${month.name}`}
              align="right"
            >
              {financialReducer(groupByUserTypeProjects["internal"] || []) +
                financialReducer(groupByUserTypeThemes["internal"] || [])}
            </TableCell>
            <TableCell
              className={classes.cellTotal}
              key={`${month.name}-external`}
              align="right"
            >
              {financialReducer(groupByUserTypeProjects["external"] || []) +
                financialReducer(groupByUserTypeThemes["external"] || [])}
            </TableCell>
          </>
        );
      })}
    </TableRow>
  );
};

export default FinancialExtractTotals;
