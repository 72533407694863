import React, { useState, useEffect } from 'react';
import UserSelect from '../Project/selects/UserSelect';
import MonthSelect from '../Project/selects/MonthSelect';
import { fetchTimeInputs } from './Api';
import { useProjects } from '../Project/ProjectsApi';
import AddTimeInput from '../Project/UserStoryTimes/AddTimeInput';
import { saveTimeInput } from '../Project/ProjectsApi';
import MaterialTable from 'material-table';
import { durationFormatter } from '../../helper/timeHelper';
import DurationFormatter from '../lib/DurationFormatter';
import {
  isEmpty,
  forEach,
  filter,
  flatten,
  groupBy,
  indexBy,
  map,
  prop,
  sum,
} from 'ramda';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import { useBoolean } from 'react-use';

export default function Projects() {
  const [userId, setUserId] = useState(
    window.localStorage.getItem('userId') || '',
  );
  const [month, setMonth] = useState('');
  const [timeInputs, setTimeInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showNewTIModal, setShowNewTiModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [userStories, setUserStories] = useState(null);
  const [timeInput, setTimeInput] = useState({});

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'user':
        window.localStorage.setItem('userId', e.target.value);
        setUserId(e.target.value);
        break;
      case 'month':
        setMonth(e.target.value);
        break;
      default:
        break;
    }
  };

  const [{ data: projects }] = useProjects(['userStories']);

  const [dicProjects, setDicProjects] = useState([]);
  const [loading, setLoading] = useBoolean(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function getTI() {
      setLoading(true);
      if (month && userId) {
        const timeInputs = await fetchTimeInputs({ userId, month });
        setTimeInputs(timeInputs.data);
        setLoading(false);
        setReload(false);
      }
    }
    getTI();
  }, [userId, month, setLoading, reload]);

  useEffect(() => {
    if (projects) {
      forEach(
        (project) =>
          forEach((us) => (us.projectId = project.id), project.userStories),
        projects,
      );
      const stories = flatten(map((project) => project.userStories, projects));
      setUserStories(indexBy(prop('id'), stories));
      setDicProjects(indexBy(prop('id'), projects || []));
    }
  }, [projects]);

  const toggleEditModal = () => setShowModal(!showModal);

  const RenderTimeInputs = (tis) => {
    if (isEmpty(tis)) return null;
    const goodDays = forEach(
      (ti) => (ti.formatedAt = moment(ti.at).format('DD/MM/YYYY')),
      tis,
    );
    const byDays = groupBy(prop('formatedAt'), goodDays);
    let total = 0;
    const createData = (groupDay) => {
      const day = groupDay[0].formatedAt;
      const hours = sum(map((task) => task.minutes, groupDay));
      const acceptance = hours >= 7 * 60;
      total += hours;
      return {
        day,
        hours,
        acceptance,
      };
    };
    const rows = map((groupDay) => createData(groupDay), byDays);
    const test = [];
    map((row) => test.push(row), rows);
    if (loading)
      return (
        <div style={{ marginLeft: '50%', marginTop: '5%' }}>
          <CircularProgress />
        </div>
      );
    return (
      <MaterialTable
        title={`Total du mois de ${month} :  ${durationFormatter(total)}`}
        columns={[
          {
            field: 'day',
            title: 'day',
            type: 'date',
            defaultSort: 'asc',
          },
          {
            field: 'hours',
            title: 'Time Spent (hours)',
            type: 'numeric',
            render: (row) => <DurationFormatter>{row.hours}</DurationFormatter>,
          },
          {
            field: 'acceptance',
            title: 'acceptance',
            type: 'numeric',
            render: (row) =>
              row.acceptance ? (
                ''
              ) : (
                <span class="material-icons" style={{ color: 'red' }}>
                  warning
                </span>
              ),
          },
        ]}
        data={test}
        actions={[
          {
            icon: 'edit',
            tooltip: 'View',
            onClick: (event, rowData) => {
              setSelectedDay(rowData.day);
              toggleEditModal(true);
            },
          },
        ]}
        options={{
          padding: 'dense',
          actionsColumnIndex: -1,
          pageSize: 20,
        }}
      />
    );
  };

  const RenderModalView = () => {
    if (!selectedDay) return null;
    const tiDay = filter((ti) => ti.formatedAt === selectedDay, timeInputs);
    return (
      <Dialog
        open={showModal}
        scroll={'paper'}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MaterialTable
              title={`Liste des entrées du ${selectedDay}`}
              columns={[
                {
                  field: 'at',
                  title: 'Renseigné à',
                  render: (row) => moment(row.at).format('LT'),
                  defaultSort: 'asc',
                },
                {
                  field: 'project',
                  title: 'Project',
                  render: (row) => {
                    if (!row.userStoryId) return '';
                    const userId = row.userStoryId;
                    const userStory = userStories[userId];
                    const projectId = userStory.projectId;
                    const project = dicProjects[projectId];
                    return project && project.name;
                  },
                },
                {
                  field: 'minutes',
                  title: 'Durée',
                  render: (row) => row.minutes / 60,
                },
                {
                  field: 'description',
                  title: 'Description',
                },
                {
                  field: 'mantisId',
                  title: 'Mantis',
                  render: (row) =>
                    row.mantisId ? (
                      <a href={row.url} target="blank">
                        {row.mantisId}
                      </a>
                    ) : (
                      ''
                    ),
                },
              ]}
              data={tiDay}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Add a time entry',
                  isFreeAction: true,
                  onClick: (e) => {
                    const ti = {
                      themeId: window.localStorage.getItem('themeId') || '',
                      userId: window.localStorage.getItem('userId') || userId,
                      sourceId: window.localStorage.getItem('sourceId') || '',
                      applicationId:
                        window.localStorage.getItem('applicationId') || '',
                      customerId:
                        window.localStorage.getItem('customerId') || '',
                      at: moment(selectedDay).format('DD/MM/YYYY'),
                      description: '',
                      contact: null,
                      minutes: '',
                    };
                    setTimeInput(ti);
                    toggleEditModal(false);
                    setShowNewTiModal(true);
                  },
                },
              ]}
              options={{
                padding: 'dense',
                actionsColumnIndex: -1,
                pageSize: 10,
              }}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <UserSelect
        id="user"
        name="user"
        label="User"
        onChange={handleChange}
        value={userId}
        fullWidth
        helperText="Choose a user"
      />

      <MonthSelect
        id="month"
        name="month"
        label="Month"
        onChange={handleChange}
        value={month}
        fullWidth
        helperText="Choose a month"
      />

      {RenderTimeInputs(timeInputs)}
      {RenderModalView()}

      <AddTimeInput
        displayContact={true}
        open={showNewTIModal}
        handleCancel={() => setShowNewTiModal(false)}
        timeInput={timeInput}
        handleSave={(values) => {
          saveTimeInput(values)
            .then(() => {
              setShowNewTiModal(false);
              setReload(true);
            })
            .catch((err) => {
              alert(err.message);
            });
        }}
      />
    </>
  );
}
