import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'devextreme/dist/css/dx.light.css';
import Keycloak from 'keycloak-js';

//keycloak init options
let initOptions = {
  url: 'https://keycloak.adsoftware-tech.com/auth',
  'enabled-cors': true,
  'public-client': true,
  realm: 'Adsoftware',
  clientId: 'projectManager-client',
  onLoad: 'login-required',
};

let keycloak = new Keycloak(initOptions);
keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      localStorage.setItem('keycloak-token', keycloak.token);
      localStorage.setItem('keycloak-refresh-token', keycloak.refreshToken);
      console.info('Authenticated');
    }

    ReactDOM.render(
      <BrowserRouter>
        <App />
        <ToastContainer
          hideProgressBar={true}
          autoClose={5000}
          position={'bottom-right'}
        />
      </BrowserRouter>,
      document.getElementById('root'),
    );

    setTimeout(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.debug('Token refreshed' + refreshed);
          } else {
            console.warn(
              'Token not refreshed, valid for ' +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000,
                ) +
                ' seconds',
            );
          }
        })
        .catch(() => {
          console.error('Failed to refresh token');
        });
    }, 60000);
  })
  .catch(() => {
    console.error('Authenticated Failed');
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
