import React from "react";
import {
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableFooter
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { orderBy, groupBy } from "lodash";
import moment from "moment";

import FinancialExtractTableFooter from "./FinancialExtractTableFooter";
import FinancialExtractTotals from "./FinancialExtractTotals";

const useStyles = makeStyles({
  firstColProj: {
    width: "35%"
  },
  firstColThem: {
    width: "30%"
  },
  tableCell: {
    width: "12%"
  },
  tableCheckBox: {
    width: "10%"
  }
});

const FinancialExtractTableBodyThemes = props => {
  const {
    monthes,
    keyToOrder,
    financialReducer,
    order,
    themes,
    timeInputsPerTheme
  } = props;
  const classes = useStyles();

  return (
    <>
      <TableBody>
        {orderBy(
          themes,
          [
            theme =>
              typeof theme[keyToOrder] === "string"
                ? theme[keyToOrder].toLowerCase()
                : theme[keyToOrder]
          ],
          order
        ).map(({ id, name, isValued }) => {
          const groupTimes = groupBy(
            timeInputsPerTheme.filter(ti => ti.themeId === id),
            x => moment(x.date_month).format("MMM")
          );
          return (
            <TableRow key={id}>
              <TableCell className={classes.firstColThem}>{name}</TableCell>
              <TableCell
                className={classes.tableCheckBox}
                align="center"
                padding="checkbox"
              >
                <Checkbox checked={isValued} />
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell
                className={classes.tableCell}
                align="right"
              ></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              {monthes.map(month => {
                const groupByUserType = groupBy(groupTimes[month.name], x =>
                  x.isExternal ? "external" : "internal"
                );
                return (
                  <>
                    <TableCell key={`${month.name}`} align="right">
                      {financialReducer(groupByUserType["internal"] || [])}
                    </TableCell>
                    <TableCell key={`${month.name}-external`} align="right">
                      {financialReducer(groupByUserType["external"] || [])}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <FinancialExtractTableFooter {...props} />
        <FinancialExtractTotals {...props} />
      </TableFooter>
    </>
  );
};

export default FinancialExtractTableBodyThemes;
