import React from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select as MSelect
} from "@material-ui/core";

export default function Select(props) {
  const { error, helperText, id, name, label, children, value, onChange, onBlur } = props;
  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <MSelect inputProps={{ id, name }} {...{id, name, label, value, onChange, onBlur}}>
        {children}
      </MSelect>
      {error && (
        <FormHelperText error={helperText}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
