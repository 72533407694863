import React, { useEffect, useCallback } from "react";
import { Modal, Paper } from "@material-ui/core";
import UserStoryAutocompleteSelect from "./UserStoryAutocompleteSelect";
import { useToggle } from "react-use";
import * as kb from "keyboardjs";
import { makeStyles } from "@material-ui/styles";
import {useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    marginLeft: "50%",
    width: 500,
    top: "150px",
    backgroundColor: "white",
    border: "2px solid #000",
    transform: "translate(-50%, -50%)"
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3)
  }
}));

export default function UserStorySearchModal() {
  const classes = useStyles();
  const history = useHistory()
  const [open, toggle] = useToggle(false);
  //const [isPressed] = useKeyboardJs("ctrl + p");
  const openModal = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      toggle(true);
    },
    [toggle]
  );

  useEffect(() => {
    kb.bind("ctrl + f", openModal);
    return () => {
      kb.unbind("ctrl + f", openModal);
    };
  }, [openModal]);

  return (
    <Modal open={open} onClose={() => toggle(false)}>
      <Paper className={classes.paper}>
        <UserStoryAutocompleteSelect onSelection={({ projectId, userStoryId }) => {
          toggle(false)
          if (userStoryId) 
            history.push(`/projects/${projectId}/backlog/${userStoryId}`) 
            else 
            history.push(`/projects/${projectId}/backlog`)
        }} />
      </Paper>
    </Modal>
  );
}
