import useAxios from 'axios-hooks';
import * as moment from 'moment';
import Axios from 'axios';
import config from '../../configuration/configuration';

const url = config.projectManagerApiUrl;
const secure = '/api/secure';

const token = localStorage.getItem('keycloak-token');
function getWithAuth(route) {
  return {
    url: `${url}${route}`,
    headers: {
      'x-access-token': token || '',
    },
  };
}

export function useUsers() {
  return useAxios(getWithAuth(`${secure}/users`));
}

function dateToString(strDate) {
  return moment(strDate).toISOString();
}

export function fetchTimeInputs(options) {
  const { month, userId } = options;

  const queries = [];
  queries.push(`userId=${userId}`);
  const start = moment().month(month).startOf('month');
  const end = moment().month(month).endOf('month');
  queries.push(`from=${dateToString(start)}`);
  queries.push(`to=${dateToString(end)}`);

  return Axios.get(
    `${url}${secure}/time-inputs/overview?${queries.join('&')}`,
    {
      headers: {
        'x-access-token': token || '',
      },
    },
  ).then((response) => {
    return response;
  });

  //   return useAxios(getWithAuth(`${secure}/time-inputs/overview?${queries.join('&')}`));
}
