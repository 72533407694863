export const calculRealCompletion = project => {
  let remainingDays = 0;
  let doneDays = 0;

  (project.userStories || []).forEach(us => {
    if (us.isDone && us.deletedAt === null) {
      doneDays += us.estimatedDays;
    } else {
      remainingDays += (us.deletedAt === null ? us.estimatedDays : 0);
    }
  });
  const totalDays = doneDays + remainingDays;
  const percentage =
    doneDays === 0 ? 0 : Math.floor((100 * doneDays) / totalDays);
  return {
    totalDays,
    remainingDays,
    doneDays,
    percentage
  };
};